import { css } from '@emotion/react'
import { Icon, spacing, Tooltip } from '@orus.eu/pharaoh'
import type { ReactElement } from 'react'

export type HelpIconProps = {
  title: string
}

export function HelpIcon({ title }: HelpIconProps): ReactElement {
  /**
   * Additional reactive space around the clickable area to make it easier to use, since icon
   * is pretty small.
   */
  const reactiveAreaPadding = `-${spacing[50]}`
  return (
    <Tooltip
      title={title}
      css={css`
        position: relative;

        &::after {
          content: '';
          position: absolute;
          cursor: pointer;
          inset: ${reactiveAreaPadding} ${reactiveAreaPadding} ${reactiveAreaPadding} ${reactiveAreaPadding};
        }
      `}
    >
      <Icon icon="circle-info-regular" />
    </Tooltip>
  )
}
